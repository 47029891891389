<template>
  <div class="PageOneBG">
    <div
      style="
        margin: 0% 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <div style="display: flex">
        <h1
          class="TitleLarge"
          style="
            font-weight: lighter;
            font-family: 'Montserrat-ExtraLight', 'Arial';
          "
        >
          BE
        </h1>
        <h1 class="TitleLarge" style="font-family: 'Montserrat-Bold', 'Arial'">
          CULTURE
        </h1>
      </div>

      <p class="ParaMedium" style="text-align: center">
        a <strong>global network</strong> for the world of
        <strong>performing arts,</strong>         
        
        <br />
        
        <strong>opera </strong>and
        <strong>concerts</strong>, 
        <strong>ballet</strong> and
        <strong> dance</strong>,
        <strong>theatre</strong>

        <br />

        and more...
      </p>

      <v-btn
        v-if="false"
        @click="scrollToElement('MissionAnchor')"
        class="learnMore"
        style="margin: 4%; width: 25vw; height: 8vw; border-radius: 50px"
        elevation="2"
        ><span style="font-size: 14px">LEARN MORE</span></v-btn
      >

      <div style="margin: 2%"></div>

      <div class="mobile">
        <v-btn
          color="grey"
          elevation="0"
          style="width: 85%; height: 80%; border-radius: 0px"
        >
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageOne",

  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
  }),

  methods: {
    scrollToElement(className) {
      const el = document.getElementsByClassName(className)[0];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style>
.Ponte-logo {
  width: 120px;
}

.PageOneBG {
  height: 550px;
  width: 100%;
  background: linear-gradient(0deg, #ffffff 0%, #ee772a 0%);
}

.mobile {
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.08);

  border-radius: 30px;
  border: 2px;
  border-color: rgba(0, 0, 0, 0.155);
  border-style: solid;

  background-color: white;

  top: 200px;

  width: 230px;
  height: 420px;
}

h1 {
  color: white;
  letter-spacing: 1.1rem;
}

p {
  color: white;
}

.FontBlack {
  color: black;
}

.TitleLarge {
  font-size: 64px;
}

.TitleMedium {
  font-size: 48px;
}

.TitleSmall {
  font-size: 24px;
}

.ParaMedium {
  font-size: 16px;
  margin: 0% !important;
  font-family: "Poppins-Light", "Arial";
}

.ParaSmall {
  font-size: 16px;
}

@media only screen and (max-width: 750px) {
  .Ponte-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
  }

  .PageOneBG {
    height: 500px;
    width: 100%;
    background: linear-gradient(0deg, #ffffff 0%, #ee772a 0%);
  }

  .mobile {
    position: absolute;
    top: 160px;

    width: 220px;
    height: 400px;
  }

  .TitleLarge {
    font-size: 48px;
  }

  .TitleSmall {
    font-size: 20px;
  }

  .ParaMedium {
    font-size: 14px;
    margin: 0% !important;
    font-family: "Poppins-Light", "Arial";
  }

  .ParaSmall {
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .PageOneBG {
    height: 400px;
    width: 100%;
    background: linear-gradient(0deg, #ffffff 0%, #ee772a 0%);
  }

  .mobile {
    position: absolute;
    top: 138px;

    width: 180px;
    height: 320px;

    border-radius: 20px;
  }

  .TitleLarge {
    font-size: 40px;
  }
}

@media only screen and (max-width: 420px) {
  .Ponte-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
  }

  .TitleLarge {
    font-size: 24px;
  }

  .PageOneBG {
    height: 320px;
    width: 100%;
    background: linear-gradient(0deg, #ffffff 0%, #ee772a 0%);
  }

  .mobile {
    position: absolute;
    top: 120px;

    width: 140px;
    height: 250px;

    border-radius: 15px;
  }

  .ParaSmall {
    font-size: 12px;
  }

  .ParaMedium {
    font-size: 12px;
  }
}
</style>