<template>
  <div style="background-color: white">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div class="MissionAnchor" style="height: 120px"></div>

      <span
        class="TitleSmall"
        style="letter-spacing: 0.3rem; font-weight: bold"
      >
        OUR
      </span>
      <h3 style="padding-bottom: 5%; font-size: 48px">FEATURES</h3>

      <hr />

      <div class="timelineElement">
        <div class="timelineElement_Tag">
          <span style="padding-right: 50%" class="MissionNumber"> 01</span>
          <!-- <v-icon size="100" color="black">mdi-magnify</v-icon> -->
          <img
            class="NumberIcon"
            src="../assets/ponteplus-search.svg"
            alt="Search+"
          />
        </div>

        <div class="timelineElement_Text">
          <p class="p_Spacing FontBlack TitleSmall">
            SEARCH <br />
            UPCOMING EVENTS
          </p>
          <p class="FontBlack ParaSmall" style="font-weight: lighter">
            You can search upcoming performing arts events around the world
            <strong> <font color="#EE772A">all in one place</font> </strong>-
            operas and concerts, ballet and dance, theatre and more
          </p>
        </div>
      </div>

      <hr />

      <div class="timelineElement">
        <div class="timelineElement_Text">
          <p class="p_Spacing TitleSmall FontBlack">
            CREATE A LOG OF <br />
            YOUR MEMORIES
          </p>
          <p style="font-weight: lighter" class="ParaSmall FontBlack">
            You can have a log of all your memories on watching performances.
            Everything only ever happens once.<br />
            <strong>
              <font color="#EE772A">Your memories are precious.</font>
            </strong>
          </p>
        </div>

        <div class="timelineElement_Tag">
          <span style="padding-left: 50%" class="MissionNumber"> 02</span>
          <!-- <v-icon size="100" color="black">mdi-calendar-blank</v-icon> -->
          <img
            class="NumberIcon"
            src="../assets/ponteplus-calendar.svg"
            alt="Search+"
          />
        </div>
      </div>

      <hr />

      <div class="timelineElement">
        <div class="timelineElement_Tag">
          <span style="padding-right: 50%" class="MissionNumber"> 03</span>
          <!-- <v-icon size="100" color="black">mdi-tag-faces</v-icon> -->
          <img
            class="NumberIcon"
            src="../assets/ponteplus-emoji.svg"
            alt="Search+"
          />
        </div>

        <div class="timelineElement_Text">
          <p class="p_Spacing TitleSmall FontBlack">
            CAPTURE <br />
            YOUR FEELINGS
          </p>
          <p class="ParaSmall FontBlack" style="font-weight: lighter">
            We know sometimes feelings are difficult to express with words. So,
            <strong> <font color="#EE772A">we have emoji</font> </strong>.
            Record your emotion in a different way.
          </p>
        </div>
      </div>

      <hr />

      <div class="timelineElement">
        <div class="timelineElement_Text">
          <p class="p_Spacing TitleSmall FontBlack">
            READ <br />
            EVENT REVIEWS
          </p>
          <p style="font-weight: lighter" class="ParaSmall FontBlack">
            You can share and read reviews of a performance, an artist, and an
            organisation. Follow others to see their thoughts and
            <strong>
              <font color="#EE772A"> exchange ideas</font>
            </strong>
            .
          </p>
        </div>

        <div class="timelineElement_Tag">
          <span style="padding-left: 50%" class="MissionNumber"> 04</span>
          <!-- <v-icon size="100" color="black"> mdi-chat-outline</v-icon> -->
          <img
            class="NumberIcon"
            src="../assets/ponteplus-review.svg"
            alt="Search+"
          />
        </div>
      </div>

      <div class="AboutAnchor"></div>

      <hr />
      <h3 style="font-size: 44px; padding: 2% 0% 0% 0%">ABOUT</h3>

      <div
        class="AboutDesBox"
        style="
          margin: 10px 20px 40px 20px;
          min-height: 35vh;
          box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.025);
        "
      >
        <p class="ParaSmall FontBlack" style="margin: 12%">
          <strong
            >Ponte+ is an event app for the world of performing arts.
          </strong>
          Finding upcoming events, creating a log of attended performances,
          having discussion before and after the events -
          <strong>all in one place.</strong>
          <br /><br />
          Performing arts is the carrier of our humanities - our history, the
          present and our future. Performing arts is the carrier of our
          humanities - our history, the present and our future. We want to bring
          humanities back to everyone's daily life.
          <br /><br />
          <strong
            >Our mission is to bring humanities back to everyone's daily
            life.</strong
          >
        </p>
      </div>
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: #ee772a;
      "
    >
      <p
        class="p_Spacing"
        style="padding-top: 5%; color: white; text-align: center"
      >
        <font size="2vw"> BE THE</font><br />
        <font size="8vw"> FIRST </font><br />
        <font size="2vw"> TO KNOW</font><br />
      </p>

      <p
        style="
          color: white;
          text-align: center;
          font-size: 16px;
          font-weight: lighter;
        "
        class="ContactAnchor"
      >
        Please fill in your contact details below:
      </p>

      <v-form
        v-model="valid"
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 0%;
          gap: 5%;
          width: 40%;
        "
      >
        <!-- <v-text-field
          placeholder="Name"
          :rules="[nameMinLengthRules, nameMaxLengthRules]"
          outlined
          clearable
          style="color: white; border-radius: 0px"
          background-color="white"
          dense
          class="custom-placeholer-color"
          v-model="post.name"
          ref="nameForm"
        ></v-text-field> -->

        <v-text-field
          placeholder="Email"
          :rules="[emailMaxLengthRules, emailRules]"
          outlined
          clearable
          style="color: white; border-radius: 0px"
          background-color="white"
          dense
          class="custom-placeholer-color"
          v-model="post.email"
          ref="emailForm"
        ></v-text-field>
      </v-form>

      <!-- <vue-recaptcha
        ref="recaptcha"
        @verift="onCaptchaVerified"
        @expired="onCaptchaExpired"
        size="invisible"
        sitekey="yourSitekey"
      ></vue-recaptcha> -->

      <!-- <vue-recaptcha sitekey="Your key here"></vue-recaptcha> -->

      <v-btn
        @click="sendForm"
        :disabled="formSend"
        :loading="submitting"
        class="submitBtn"
        elevation="0"
        ><span v-if="formSend == false" span>Send</span
        ><span v-if="formSend == true" style="color: white" span
          >Keep In Touch!</span
        ></v-btn
      >

      <div
        class="ParaSmall"
        style="
          width: 100%;
          height: 100px;
          color: white;
          background-color: black;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
        "
      >
        <div
          style="
            width: 30%;
            height: 100px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          "
        >
          <v-col style="width: 30%">
            <v-btn icon color="white" href="https://www.facebook.com/ponteplus">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
          </v-col>

          <v-col style="width: 30%">
            <v-btn
              icon
              color="white"
              href="https://www.instagram.com/ponteplus/"
            >
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
          </v-col>

          <v-col style="width: 30%">
            <v-btn
              icon
              color="white"
              href="https://www.linkedin.com/company/ponteplus"
            >
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
          </v-col>
        </div>

        <div style="text-align: center; width: 30%">
          <a
            href="mailto:hello@ponteplus.com"
            style="color: white; text-decoration: none"
          >
            hello@ponteplus.com
          </a>
        </div>

        <div
          style="
            width: 30%;
            height: 100px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
          "
        >
          <v-col
            style="
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            "
          >
            <v-btn icon color="white" @click="scrollToTop">
              <v-icon>mdi-format-vertical-align-top</v-icon>
            </v-btn>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>


<script>
export default {
  name: "PageTwo",

  data: () => ({
    valid: false,
    nameForm: null,
    emailForm: null,

    formSend: false,
    submitting: false,

    emailMaxLengthRules: (v) => v.length <= 48 || "Max 48 characters",

    nameMinLengthRules: (v) => v.length > 2 || "Min 3 characters",
    nameMaxLengthRules: (v) => v.length <= 28 || "Max 28 characters",

    emailRules: (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },

    post: {
      name: "em",
      email: "",
    },
  }),

  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("Entrance");
          return;
        }

        entry.target.classList.remove("Entrance");
      });
    });

    document.querySelectorAll(".MissionNumber").forEach((section) => {
      observer.observe(section);
    });
  },

  methods: {
    scrollToElement(className) {
      const el = document.getElementsByClassName(className)[0];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },

    onEvent() {
      // when you need a reCAPTCHA challenge
      // this.$refs.recaptcha.execute();
    },

    sendForm() {
      this.submitting = true;

      if (!this.valid) {
        // this.$refs["nameForm"].validate(true);
        this.$refs["emailForm"].validate(true);
        console.log("val");
        this.submitting = false;
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: this.post.name, email: this.post.email }),
      };
      fetch(
        "https://ponteplus0909-default-rtdb.asia-southeast1.firebasedatabase.app/posts.json",
        requestOptions
      )
        .then(async (response) => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          } else {
            this.formSend = true;
          }

          this.postId = data.id;
          this.submitting = false;
        })
        .catch((error) => {
          this.errorMessage = error;
          console.error("There was an error!", error);
        });
    },
  },
};
</script>

<style>
.timelineElement {
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.timelineElement_Text {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timelineElement_Tag {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MissionNumber {
  font-size: 100px;
  color: #ee772a;
  font-weight: bold;
  font-family: "Montserrat-Bold", "Arial";
}

.p_Spacing {
  letter-spacing: 0.14rem;
  font-family: "Montserrat-Bold", "Arial";
  font-weight: bold;
}

hr {
  border: none;
  border-left: 2px dotted rgba(0, 0, 0, 0.095);
  color: #fff;
  background-color: #fff;
  /* width: 2px; */
  height: 10vw;
}

h3 {
  color: #ee772a;
  font-size: 44px;
  letter-spacing: 0.5rem;
  font-weight: normal;
  font-family: "Montserrat-Bold", "Arial";
}

/* .custom-placeholer-color {
  font-size: 0.8vw !important ;
}

.custom-placeholer-color .v-label--active {
  font-size: 2.5vw !important ;
  color: grey !important ;
} */

.v-messages__message {
  color: white;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: black !important;
}

.submitBtn {
  margin-bottom: 2%;
  width: 15%;
  height: 4vw;
  border-radius: 0px;
  font-size: 12px;
  color: white;
  background-color: rgba(255, 255, 255, 0.5);
}

.submitBtn:disabled {
  margin-bottom: 2%;
  width: 18%;
  height: 4vw;
  border-radius: 50px;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  background-color: rgba(0, 0, 0, 1);
  transition: all 0.8s;
}

.NumberIcon {
  width: 12vw;
}

.AboutDesBox {
  width: 40vw;
}

@media only screen and (max-width: 750px) {
  .timelineElement {
    margin: 40px;
  }

  .AboutDesBox {
    width: 80vw;
  }

  .NumberIcon {
    width: 20vw;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .Entrance {
    animation-name: wipe-enter;
    animation-duration: 2s;
    animation-iteration-count: 1;
  }
}

@keyframes wipe-enter {
  0% {
    opacity: 0;
  }
}
</style>