import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

// import { createRouter, createRoutermcreateWebHistory, createWebHistory } from 'vue-router'

// const router = createRouter({
//   history: createWebHistory(),
//   routes: [
//     { path: '/', name: 'Home', component: Home }

//   ]

// })

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
