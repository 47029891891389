<template>
  <v-app>
    <v-app-bar app color="primary" elevation="0">
      <div class="d-flex align-center">
        <img
          class="Ponte-logo"
          src="./assets/logo-for_website.png"
          alt="Ponte+"
        />

        <!-- <h4 class="MainTitle" style="letter-spacing: 0.3rem; color: white">
          Ponte+
        </h4> -->
      </div>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon
        v-if="false"
        style="color: white"
        @click="drawer = true"
      ></v-app-bar-nav-icon>

      <div v-if="false" class="AppBarButtons">
        <v-btn target="_blank" text>
          <span
            @click="scrollToElement('MissionAnchor')"
            class="AppBarButtonText"
            >Mission</span
          >
        </v-btn>

        <v-btn target="_blank" text>
          <span @click="scrollToElement('AboutAnchor')" class="AppBarButtonText"
            >About</span
          >
        </v-btn>

        <v-btn target="_blank" text>
          <span
            @click="scrollToElement('ContactAnchor')"
            class="AppBarButtonText"
            >Contact</span
          >
        </v-btn>
      </div>
    </v-app-bar>

    <v-main style="height: 100%">
      <PageOne />
      <PageTwo />
    </v-main>

    <v-navigation-drawer
      v-if="false"
      style="background-color: #ee772a; width: 100px; height: 100vh"
      v-model="drawer"
      app
      right
      temporary
    >
      <v-list nav dense>
        <v-list-item-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item
            @click="scrollToElement(child.title + 'Anchor')"
            v-for="child in item.items"
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title
                style="color: white"
                v-text="child.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import PageOne from "./components/PageOne";
import PageTwo from "./components/PageTwo";

export default {
  name: "App",

  components: {
    PageOne,
    PageTwo,
  },

  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
  },

  created() {
    window.addEventListener("resize", this.orientationCheck);
  },

  methods: {
    orientationCheck() {
      this.landscape = window.innerWidth > window.innerHeight ? true : false;
    },

    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        this.landscape = false;
      } else if (orientation === "landscape-primary") {
        this.landscape = true;
      }
    },

    scrollToElement(className) {
      console.log(className);
      const el = document.getElementsByClassName(className)[0];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },

  data: () => ({
    drawer: false,
    group: null,
    landscape: true,

    items: [
      {
        action: "mdi-format-list-bulleted",
        active: true,
        items: [{ title: "Mission" }, { title: "About" }, { title: "Contact" }],
      },
    ],
  }),
};
</script>

<style>
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  src: local("Montserrat-ExtraLight"),
    url("./assets/fonts/Montserrat-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
    url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
    url("./assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Thin"),
    url("./assets/fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: thin;
}

@media all and (orientation: portrait) {
}

@media all and (orientation: landscape) {
}

.AppBarButtonText {
  color: white;
  font-size: 14px;
}

strong {
  font-weight: bold;
}

</style>